<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card v-if="isPageLoading" no-body class="p-4">
              <b-card-body>
                <div class="text-center">
                  <i class="fa fa-cog fa-spin fa-4x"></i>
                </div>
              </b-card-body>
            </b-card>
            <b-card v-if="!isPageLoading" no-body class="p-4">
              <b-card-body>
                <b-form>
                  <h2>Изменение пароля</h2>
                  <b-input-group
                    v-if="changeParams.token.length < 1"
                    class="mb-3"
                  >
                    <b-input-group-prepend>
                      <b-input-group-text
                        ><i class="icon-key"></i
                      ></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      class="form-control"
                      placeholder="Введите старый пароль"
                      v-model.trim="changeParams.old"
                    ></b-form-input>
                  </b-input-group>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text
                        ><i class="icon-key"></i
                      ></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      class="form-control"
                      placeholder="Придумайте новый пароль"
                      v-model.trim="$v.changeParams.new.$model"
                      :class="{ 'is-invalid': $v.changeParams.new.$error }"
                    ></b-form-input>
                  </b-input-group>
                  <div class="invalid-error" v-if="$v.changeParams.new.$error">
                    <span
                      >Пароль слишком слабый. Он должен соответствовать
                      следующим требованиям: иметь (как минимум) одну заглавную
                      латинскую букву, одну цифру и состоять из 6
                      символов.</span
                    >
                  </div>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text
                        ><i class="icon-key"></i
                      ></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      class="form-control"
                      placeholder="Подтвердите новый пароль"
                      v-model.trim="$v.changeParams.new_again.$model"
                      :class="{
                        'is-invalid': $v.changeParams.new_again.$error
                      }"
                    ></b-form-input>
                  </b-input-group>
                  <div
                    class="invalid-error"
                    v-if="!$v.changeParams.new_again.sameAsPassword"
                  >
                    <span>Пароли не соответствуют друг другу.</span>
                  </div>
                  <b-row>
                    <b-col cols="12" class="text-right">
                      <b-button
                        variant="primary"
                        @click="submitChange()"
                        class="px-4"
                        :disabled="$v.$invalid"
                        >Изменить</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import passwordComplexity from "../customValidators/passwordComplexity";
import API from "../api/stalk.net";
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "PassChange",
  data() {
    return {
      changeParams: {
        token: this.$route.params.token,
        user_uuid: this.$route.params.uuid,
        old: "",
        new: "",
        new_again: ""
      },
      currentAlertCounter: 0,
      isPageLoading: false
    };
  },
  methods: {
    submitChange() {
      let self = this;

      self.isPageLoading = true;
      API.changePassword(self.changeParams)
        .then(() => {
          self.isPageLoading = false;
          this.$router.replace("/login");
        })
        .catch(e => {
          console.error(e);
          self.showLoginError({ message: e.response });
          self.isPageLoading = false;
        });
    }
  },
  validations: {
    changeParams: {
      new: {
        required,
        minLength: minLength(6),
        passwordComplexity
      },
      new_again: {
        sameAsPassword: sameAs("new")
      }
    }
  },
  notifications: {
    showLoginError: {
      // You can have any name you want instead of 'showLoginError'
      title: "Ошибка изменения",
      //message: e,
      type: "error" // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  }
};
</script>

<style local>
.invalid-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
}
</style>
